import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { Fade } from "react-awesome-reveal";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      craftFooterGlobalSet {
        contacts {
          ... on Craft_contacts_contact_BlockType {
            id
            position
            phoneNumber
            emailAddress
            contactName
          }
        }
        address
      }
      craftSocialGlobalSet {
        instagramHandle
        facebookHandle
        linkedinHandle
        vimeoHandle
      }
    }
  `)

  const { contacts, address } = data.craftFooterGlobalSet
  const { instagramHandle, facebookHandle, linkedinHandle, vimeoHandle } =
    data.craftSocialGlobalSet

  return (
    <footer>
      {/* Contact */}
      <Fade delay={400} triggerOnce>
        <div className="bg-green py-20 xl:py-40">
          <div className="gutter">
            <div className="md:flex md:-ml-20 xl:-ml-75 mb-15 md:mb-30">
              <div className="md:w-1/2 md:pl-20 xl:pl-75">
                <svg
                  className="fill-current h-50 xl:h-100"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 329.31 394.74"
                >
                  <path d="M164.65 0L0 113.97v102.65l164.65 178.12 164.66-178.12V113.97L164.65 0z" />
                  <path
                    d="M150.55 300c0 2.56 2.41 4.3 14.86 4.3 11.62 0 13.35-.76 13.35-2.72 0-2.26-1.73-2.57-14.11-2.94-23.83-.76-33-4.68-33-17 0-11.61 11.39-16.59 32.21-16.59 20.59 0 32.21 5 32.21 18.48H176.5c0-2.79-4.53-3.39-14.11-3.39-9.2 0-11.09.45-11.09 2.49s1.89 2 13.35 2.41c20.82.83 33.72 2.26 33.72 16.07 0 14.56-12.07 18.25-33.72 18.25s-33.72-3.69-33.72-19.38zm53.29-59.07c4.23 0 11.17 0 11.17-9.81s-6.94-9.8-11.17-9.8h-13.58v19.61h13.58m6-36.21c12.75 0 24.74 8.45 24.74 26.4s-12 26.4-24.74 26.4h-39.19V204.7h39.23m-46.4 0v52.8h-25.64l-22.79-31.15v31.15H95.44v-52.8h25.65l22.78 31.15V204.7zm83.7-24.14c11.54 0 13.58-5.05 13.58-10.64s-2-10.48-13.58-10.48-13.58 4.9-13.58 10.48 2 10.64 13.58 10.64m0 16.6c-23.23 0-33.19-8.83-33.19-27.24s10-27.08 33.19-27.08 33.19 8.83 33.19 27.08-10 27.24-33.19 27.24m-38.85-.76h-19.61v-25.12l-14.41 25.12h-12.82l-14.41-25.12v25.12h-19.62v-52.8h24.14l16.3 30.17 16.29-30.17h24.14zM91.48 173.32l-6.11-16.15-6.11 16.15zm5.13 13.58H74.13l-3.62 9.5H48.93l21.88-52.8h29.12l21.88 52.8h-21.58zm113-51.6H190V82.49h19.61zm-56.8-16.6c4.22 0 11.16 0 11.16-9.8s-6.94-9.81-11.16-9.81h-13.54v19.61h13.58m6-36.21c12.75 0 24.75 8.45 24.75 26.41s-12 26.4-24.75 26.4h-39.19V82.49h39.22"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div className="md:w-1/2 md:pl-20 xl:pl-75">
                <div className="lg:text-xl xl:text-2xl pt-10 xl:pt-20">
                  <h3 className="font-heading font-medium uppercase mb-2">
                    Diamonds Helsinki
                  </h3>
                  <div className="font-light">
                    {address.split("\n").map(line => (
                      <div key={line.toLowerCase()}>
                        {line}
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:text-xl xl:text-2xl -mb-10 md:flex md:-ml-20 xl:-ml-75">
              {contacts.map(contact => (
                <div
                  className="pb-10 md:w-1/2 md:pl-20 xl:pl-75"
                  key={contact.id.toLowerCase()}
                >
                  <h3 className="font-heading font-medium uppercase mb-2">
                    {contact.contactName}
                  </h3>
                  <ul>
                    <li className="font-medium">{contact.position}</li>
                    <li>
                      <a
                        className="font-light hover:opacity-75"
                        href={`mailto:${contact.emailAddress}`}
                      >
                        {contact.emailAddress}
                      </a>
                    </li>
                    <li>
                      <a
                        className="font-light hover:opacity-75"
                        href={`tel:${contact.phoneNumber}`}
                      >
                        {contact.phoneNumber}
                      </a>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fade>

      {/* Social */}
      <Fade delay={400} triggerOnce>
        <div className="py-15 xl:py-35">
          <div className="text-center gutter">
            <ul className="inline-flex align-middle space-x-10 xl:space-x-20">
              <li>
                <a
                  href={`https://www.instagram.com/${instagramHandle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="h-8 lg:h-12 hover:text-dark-blue fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50.13 50.13"
                  >
                    <path d="M25.07 4.52c6.69 0 7.48 0 10.13.14a13.69 13.69 0 014.65.87 8.31 8.31 0 014.76 4.75 14 14 0 01.86 4.66c.12 2.64.15 3.43.15 10.13s0 7.48-.15 10.13a13.92 13.92 0 01-.86 4.65 8.33 8.33 0 01-4.76 4.76 13.92 13.92 0 01-4.65.86c-2.65.12-3.44.15-10.13.15s-7.49 0-10.13-.15a14 14 0 01-4.66-.86 8.31 8.31 0 01-4.75-4.76 13.69 13.69 0 01-.87-4.65c-.12-2.65-.14-3.44-.14-10.13s0-7.49.14-10.13a13.74 13.74 0 01.87-4.66 8.29 8.29 0 014.75-4.75 13.74 13.74 0 014.66-.87c2.64-.12 3.43-.14 10.13-.14m0-4.52c-6.81 0-7.66 0-10.34.15a18.28 18.28 0 00-6.08 1.17 12.78 12.78 0 00-7.33 7.33 18.28 18.28 0 00-1.17 6.08C0 17.41 0 18.26 0 25.07s0 7.66.15 10.33a18.39 18.39 0 001.17 6.09 12.84 12.84 0 007.33 7.33A18.27 18.27 0 0014.73 50c2.68.12 3.53.15 10.34.15s7.66 0 10.33-.15a18.38 18.38 0 006.09-1.16 12.9 12.9 0 007.33-7.33A18.38 18.38 0 0050 35.4c.12-2.67.15-3.53.15-10.33s0-7.66-.15-10.34a18.27 18.27 0 00-1.16-6.08 12.84 12.84 0 00-7.33-7.33A18.39 18.39 0 0035.4.15C32.73 0 31.87 0 25.07 0zm0 12.19a12.88 12.88 0 1012.87 12.88 12.88 12.88 0 00-12.87-12.88zm0 21.23a8.36 8.36 0 118.35-8.35 8.35 8.35 0 01-8.35 8.35zM38.45 8.68a3 3 0 103 3 3 3 0 00-3-3z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={`https://vimeo.com/${vimeoHandle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="h-8 lg:h-12 hover:text-dark-blue fill-current"
                    viewBox="0 0 54.31 47.07"
                  >
                    <path d="M54.28 10.89q-.36 8-11.07 21.76-11.08 14.42-18.74 14.42-4.75 0-8-8.78l-4.42-16.11Q9.61 13.4 6.82 13.4A16.48 16.48 0 002.56 16L0 12.66q4-3.54 7.93-7.08Q13.29.93 16 .69q6.34-.61 7.81 8.69c1 6.68 1.79 10.84 2.19 12.47q1.83 8.32 4 8.31c1.13 0 2.84-1.79 5.12-5.39a21.32 21.32 0 003.66-8.22c.32-3.1-.9-4.66-3.66-4.66a10.11 10.11 0 00-4 .89Q35.11-.36 46.39 0q8.38.25 7.89 10.89z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/${facebookHandle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="h-8 lg:h-12 hover:text-dark-blue fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.31 53.98"
                  >
                    <path d="M54.31 27.1A27.16 27.16 0 1022.91 54V35H16v-7.9h6.89v-6c0-6.82 4.06-10.58 10.26-10.58a41.24 41.24 0 016.08.53v6.69h-3.41a3.93 3.93 0 00-4.4 3.39 3.54 3.54 0 000 .86v5.11h7.53L37.73 35H31.4v19a27.2 27.2 0 0022.91-26.9" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={`https://www.linkedin.com/company/${linkedinHandle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="h-8 lg:h-12 hover:text-dark-blue fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50.13 50.13"
                  >
                    <path d="M46.42 0H3.7A3.66 3.66 0 000 3.61v42.91a3.65 3.65 0 003.7 3.61h42.72a3.65 3.65 0 003.71-3.61V3.61A3.67 3.67 0 0046.42 0zM14.87 42.72H7.43V18.8h7.44zm-3.72-27.2a4.31 4.31 0 114.31-4.31 4.31 4.31 0 01-4.31 4.31zm31.57 27.2h-7.43V31.09c0-2.78 0-6.35-3.86-6.35S27 27.76 27 30.88v11.84h-7.47V18.8h7.14v3.26h.09a7.83 7.83 0 017-3.86c7.53 0 8.92 5 8.92 11.4z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Fade>
    </footer>
  )
}

export default Footer
