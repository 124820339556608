/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="font-body text-charcoal antialiased">
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Ymmärrän"
        buttonStyle={{
          background: "rgb(53, 53, 53)",
          color: "white",
          border: "1px solid white",
        }}
        style={{
          "font-family": "monument-extended",
          "font-size": "12px",
        }}
      >
        Tämä sivusto käyttää evästeitä
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
